import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslationService } from '../../../../services/translation.service';
import { environment } from 'src/environments/environment';
import { AwsAuthService } from '../../../../services/aws-auth.service';

@Component({
  selector: 'app-no-route',
  templateUrl: './no-route.component.html',
  styleUrls: ['./no-route.component.scss']
})
export class NoRouteComponent implements OnInit {
  isUser: any;
  isUserLoggedIn: any;
  mySubscription: any;
  translationData = []
  featureFlags: any;
  isProductionEnv: boolean = true;

  constructor(private router: Router, private translationService: TranslationService, private _awsAuthService: AwsAuthService) {
    let access_token: any;
    this.featureFlags = this.translationService.featureFlags;
    this.isProductionEnv = this.featureFlags.general.tempProdFlag;

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isUser = localStorage.getItem('userLoggedIn');
        access_token = localStorage.getItem('access_token');
        this.isUserLoggedIn = this._awsAuthService.getUserLoggedInStatus()
      }
    });
  }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

}
