import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { Location } from '@angular/common';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-ciam-login',
  templateUrl: './ciam-login.component.html',
  styleUrls: ['./ciam-login.component.scss'],
})
export class CiamLoginComponent implements OnInit {
  language = '';
  constructor(
    private router: Router,
    private awsAuth: AwsAuthService,
    private location: Location,
    private languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.awsAuth.isLoginSuccess.subscribe((success: boolean) => {
      if (success) {
        this.initiateNavigation()
      }
    })
  }

  public initiateNavigation(): void {
    const url = localStorage.getItem('routeUrl');
    const eventId = sessionStorage.getItem('eventId');
    const participationDetails = sessionStorage.getItem('partcipationDetails');
    this.awsAuth.setUserLoggedInStatus();

    if (participationDetails && !url?.includes('register')) {
      this.router.navigateByUrl(`/${this.language}/stories/link-participant`);
    } else if (url?.includes('register')) {
      this.router.navigateByUrl(`/${this.language}/stories/register/${eventId}`);
    } else {
      this.navigateToUrlOrHome(url);
    }
  }

  public navigateToUrlOrHome(url: string | null) {
    if (url) {
      this.router.navigateByUrl(`/${url}`);
      this.location.go(`/${url}`);
      localStorage.removeItem('routeUrl');
    } else {
      const homeUrl = `/${this.language}/home`;
      this.router.navigateByUrl(homeUrl);
      this.location.go(homeUrl);
    }
  }
}
