import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AwsAuthService {

  public isUserLoggedIn: boolean = false;
  public code: any;

  private _loginStatusSubject = new BehaviorSubject<boolean>(false);

  constructor(private _httpClient: HttpClient, private _route: ActivatedRoute) {
    this.getAuthCode();
  }

  public signIn(): Observable<any> {
    const url = environment.proxyEndpoint + '/auth/federatedSignIn';
    return this._httpClient.get<any>(url);
  }

  public manageLoginResponse(): void {
    this.signIn().subscribe((response: any) => {
      window.location.href = response.loginUrl;
    })
  }

  public getAuthCode(): void {
    this._route.queryParams['subscribe']((params: { [x: string]: any; }) => {
      this.code = params['code'];
      if (this.code) {
        this.callGenerateAuthTokenApi(this.code).subscribe(response => {
          if (response) {
            this.setUserLoggedInStatus();
            const responseBody = response.body
            this.isLoginSucess = responseBody.success
          }
        });
      }
    });
  }

  public callGenerateAuthTokenApi(code: any): Observable<any> {
    const url = `${environment.proxyEndpoint}/auth/token?code=${code}`;
    return this._httpClient.get<any>(url, {observe: 'response', withCredentials: true});
  }

  public logout() {
    this.clearLocalStorageItems();
    const url = environment.proxyEndpoint + '/auth/signout';
    return this._httpClient.get<any>(url);
  }

  public manageLogoutResponse(): void {
    this.logout().subscribe((response: any) => {
      window.location.href = response.logout_uri
    }); 
  }

  public setUserLoggedInStatus(): void {
    localStorage.setItem('isLoggedIn', 'true');
  }

  public getUserLoggedInStatus(): boolean {
    return localStorage.getItem('isLoggedIn') === 'true' || false;
  }

  public clearLocalStorageItems() {
    localStorage.removeItem('isBasicAuthSuccess');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('routeUrl');
  }

  public set isLoginSucess(success: boolean) {
    this._loginStatusSubject.next(success);
  }

  public get isLoginSuccess() {
    return this._loginStatusSubject.asObservable();
  }

}
