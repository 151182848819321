import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { TranslationService } from 'src/app/services/translation.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { ProfileDetails } from '../interfaces/myStories.interface';
import { MyStoriesService } from 'src/app/modules/stories/services/my-stories.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menuSelected = false;
  currentUser: any;
  showHeader:any = true;
  selectedCategory: string = '/explore'
  toggleHeader:any = environment.isEnabled;
  translationData = [];
  language = this.languageService.getLanguage();
  showMenu = false;
  selectedLang:any;
  showBottomMenu: any;
  screenWidth: any;
  isHeaderMin = false;
  isProfileClicked = false;
  videoplayed: any = 'false';
  featureFlags: any;
  isProd = environment.production;
  public showMyProfileLink: boolean = false;
  public showLoginLink: boolean = false;
  languageToDisplay: string = 'Deutsch';
  showLangPicker: boolean = false;
  secondLastUrlElement: string = '';
  profileInfo: ProfileDetails = <any> null;
  profileImage =  "../../../../../assets/images/profile-fallback.png";
  isMyProfileVisible:boolean = false;
  isLanguageSelector: boolean = false;
  public isHeadingAvailable : boolean = false;
  public isMyStoriesVisible: boolean = false;
  public enableMyProfile: boolean = false;
  showLogout: boolean = false;

  constructor(private router: Router, 
    private location: Location,
    private awsAuth: AwsAuthService, 
    private translationService: TranslationService,
    private languageService: LanguageService,
    private _storiesService: MyStoriesService,
    private renderer: Renderer2) {
    this.featureFlags = this.translationService.featureFlags;
    let selectedLanguage = localStorage.getItem('language');
    if (!selectedLanguage) {
      selectedLanguage = 'en-GB';
    }
    this.selectedLang = selectedLanguage;
    // This is done for CIAM related pages, where we have to show the header dynamic language.
    // This is set based on participant locale we get from API
    this.languageService.participantLocale.subscribe((locale: any) => {
      this.getUserSelectedLanguage(locale);
    });
	}

  ngOnInit() {
    this.language = this.languageService.getLanguage();
    this.getLangSubscription()
    this.getUserSelectedLanguage(this.language);
    let videoStatus = sessionStorage.getItem('videoplayed');
    if(videoStatus){
      this.videoplayed = videoStatus;
    }
    if(this.videoplayed === 'true'){
        this.isHeaderMin = true;
    }
    this.handleFeatureFlags();
    this.handleProfileTabVisibilty()
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    setTimeout(()=>{
      this.isHeaderMin = true
    }, 3600);
  }

  handleProfileTabVisibilty() {
    this.awsAuth.isLoginSuccess.subscribe((isLoggedIn: boolean) => {
      this.currentUser = isLoggedIn || this.awsAuth.getUserLoggedInStatus();
      if (this.currentUser) {
        this.showMyProfileHeaderOnLogin();
      } else {
        this.showMyProfileLink = false;
        this.showLoginLink = true;
      }
      const currentUrl = this.location.path();
      this.selectedCategory = currentUrl.includes('/stories') ? '/stories' : '/explore';
    });
  }

  public handleFeatureFlags() {
    this.isProd = this.featureFlags.general.tempProdFlag;
    this.isMyStoriesVisible = this.featureFlags.circuitDetails.enableStories;
    this.enableMyProfile = this.featureFlags.general.enableMyProfile;
    if(this.featureFlags.general.enableLanguageSelector){
      this.isLanguageSelector = true;
    }
    if(this.featureFlags.general.enableMyProfile){
      this.isMyProfileVisible = true;
    }
    if(this.featureFlags.circuitDetails.enableStories){
      this.isHeadingAvailable = true;
    }
  }

  public getLangSubscription(): void {
    this.languageService.shareParticipantLanguage.subscribe((data) => {
      const supportedLocales: any = {
        'en-GB': 'English',
        'de-DE': 'Deutsch',
        'de': 'Deutsch',
        'ko-KR': 'Korean',
        'ko': 'Korean',
        'fr-FR': 'French',
        'fr': 'French',
        'es-ES': 'Spanish',
        'es': 'Spanish',
        'en': 'English',
        'en-US': 'English',
        'cs-CZ': 'Czech',
      }
      this.languageToDisplay = supportedLocales[data] ?? 'English';
    })
  }

  logout() {
    this.awsAuth.clearLocalStorageItems();
    this.awsAuth.manageLogoutResponse();
  }

  openLanguagePicker(){
    this.languageService.showLanguageSelectionPage(true);
  }

  showDropMenu(){
    this.showMenu = true;
  }

  vidEnded(){
    sessionStorage.setItem('videoplayed','true')
  }

  hideMenuInMobile(){
    this.screenWidth = window.innerWidth;
    if(this.screenWidth < 1024){
      this.showMenu = false;
    }
  }

  hideDropMenu() {
    this.showMenu = false;
  }

  showDropDownInMobile(){
    this.showBottomMenu = !this.showBottomMenu;
    if(this.showBottomMenu){
      this.renderer.setStyle(document.body, 'overflow','hidden');
    } else{
      this.renderer.removeStyle(document.body, 'overflow');
    }
  }

  goToHome(){
    this.location.go('/'+this.language+'/explore');
    window.location.reload();
    localStorage. removeItem('filter');
  }

  /**
   * @description If user lang is english but broswer language diff and is part of our supported lang - that lang.
   * If user lang and and browser default is Engligh -> German
   * If lang is not supported - then 404
   * @param locale
   */
  public getUserSelectedLanguage(participantLang: string) {
    let urlLang = participantLang;
    const supportedLocales: any = {
      'en-GB': 'English',
      'de-DE': 'Deutsch',
      'de': 'Deutsch',
      'ko-KR': 'Korean',
      'ko': 'Korean',
      'fr-FR': 'French',
      'fr': 'French',
      'es-ES': 'Spanish',
      'es': 'Spanish',
      'en': 'English',
      'en-US': 'English',
      'cs-CZ': 'Czech',
    }
    const fullURL = window.location.href.split('/');
    const langIndex = fullURL.findIndex(part => this.languageService.checkIsValideLanguage(part));
    let isEngilsh = (lang: string) => lang && lang.includes('en') ? true : false;
    if (langIndex !== -1) {
      urlLang = fullURL[langIndex];
    }
    if(this.featureFlags.general.enableLanguageSelector){
      this.languageToDisplay = supportedLocales[urlLang] ?? 'English';
    } else{
      const locale = isEngilsh(urlLang) ? participantLang : urlLang;
      const selectedLang = (url: string) => supportedLocales[url];
      this.languageToDisplay = !isEngilsh(locale) ? selectedLang(locale) : selectedLang(isEngilsh(this.language) ? 'de-DE' : this.language);
    }

  }

  /**
   * @description This code is for older UI, do  we need to keep it?
   * @param language 
   */
  public switchLanguage(language:string) {
    const supportedLocales: any = {
      'English': 'en-GB',
      'Deutsch': 'de-DE',
      'Korean': 'ko-KR',
      'French': 'fr-FR',
      'Spanish': 'es-ES',
      'Czech': 'cs-CZ'
    }
    const selectedLang = (url: string) => supportedLocales[url] ? supportedLocales[url] : 'de-DE';
    this.languageToDisplay = language;
    const fullURL = window.location.href.split('/');
    const langIndex = fullURL.findIndex(part => this.languageService.checkIsValideLanguage(part));
    fullURL[langIndex] = selectedLang(language);
    localStorage.setItem('language', selectedLang(language));
    window.location.href = fullURL.join('/');
    this.selectedLang = language;
  }

  triggerLogin() {
    localStorage.removeItem('currentCIAMUser');
    localStorage.removeItem('isLoggedIn')
    this.awsAuth.manageLoginResponse()
  }

  /**
   * @description Non-Prod:- Method to handle navigation to my stories page
   * We need to go to CIAM login page if user is not logged in
   * Upon logging in, I should be redirected to the same page I was last on
   * Prod:- Method to handle navigation to home page and no Login/Explore buttons visible to user
   */
  public handleMyStoriesNavigationClick(): void {
    this.isProd = this.featureFlags.general.tempProdFlag;
    this.showBottomMenu = false;
    switch (true) {
      case this.currentUser && this.isHeadingAvailable:
        this.router.navigateByUrl(`${this.language}/stories`);
        this.selectedCategory = '/stories';
        break;
      case !this.currentUser && this.isHeadingAvailable:
        localStorage.setItem('routeUrl', '/' + this.selectedLang + '/stories');
        this.triggerLogin();
        break;
      case this.isProd:
        this.router.navigateByUrl(`${this.language}/home`);
        break;
      case !this.currentUser && !this.isHeadingAvailable:
        this.goToHome();
        break;
      default:
        this.router.navigateByUrl(`${this.language}/home`);
        break;
    }
  }

  /**
   * @description Method to show/hide my profile and login links in header
   * called either on login or on page load
   */
  public showMyProfileHeaderOnLogin(): void {
      if (!this.currentUser) {
      this.showMyProfileLink = false;
      this.showLoginLink = true;
    } else if (this.currentUser && this.isHeadingAvailable) {
      this._storiesService.getProfile().subscribe(
        (data: ProfileDetails) => {
          this._storiesService.ciamId = data.userId;
         this._storiesService.profileInfoResponse = data;
         this.profileInfo = data;
         this.showMyProfileLink = true;
         this.showLoginLink = false;
        }, (error: any) => {
          this.showMyProfileLink = false;
          this.showLoginLink = false;
          this.showLogout = true
        });
    }
  }

  profileClicked(){
    if(this.featureFlags.general.enableMyProfile)
    this.isProfileClicked=true;

  }
  cancelClicked(event:any){
    if(event){
      this.isProfileClicked=false
    }
  }

  public navigateToProviderPage(): void {
    this.router.navigate(['/' + this.selectedLang + '/provider']);
    this.showDropDownInMobile();
  }

  public navigateToPrivacyPage(): void {
    this.router.navigate(['/' + this.selectedLang + '/privacy']);
    this.showDropDownInMobile();
  }

  getLangId(event:any){
    this.switchLanguage(event);
  }

}

