export const environment = {
  enableSummaryButton: false,
  enableTourLines: false,
  setAccurateFade:false,
  isEnabled:true,
  production: false,
  apiEndPoint: 'https://amgmaa-nonprod.mercedes-benz.com/aca',
  proxyEndpoint:'https://proxy.int.stories.me/customerPortal',
  serverUrl: 'https://api.int.business.stories.me/',
  issuer: 'https://ciam-auth.int.stories.me/oauth2/authorize',
  tokenURL: 'https://ciam-auth.int.stories.me/oauth2/token',
  revokeTokenURL: 'https://ciam-auth.int.stories.me/oauth2/revoke',
  redirectUri: 'https://int.stories.me/race/login',
  raceSharingPageUrl: 'https://int.stories.me/race',
  domainUrl:'https://int.stories.me/',
  aesBaseUrl: "https://apis.int.business.stories.me/aes",
  ausBaseUrl: "https://apis.int.business.stories.me/aus",
  logoutUrl: 'https://ciam-auth.int.stories.me/logout?client_id=3gdv42jnbndat5iiis28agj3ej&logout_uri=https://int.stories.me/race/logout',
  phraseUrl: 'https://api.phrase.com/v2/projects/5704f8ba49ae1e6a8ac365c64b2650de/locales/@languageId/translations?access_token=12d65253353cc19d0a84bed6af5cb1d64b20df3121313110d7a0c4159c320dbe',
  raceUrl:'https://api.int.business.stories.me/',
  newraceUrl:'https://apis.int.business.stories.me/aes/',
  featureTogglePublish:false,
  phraseEndPoint: 'https://api.phrase.com/v2/projects/69f7cea3aa5ebe03d2bb58e5bbda250b/locales',

  clientId: '3gdv42jnbndat5iiis28agj3ej',
  responseType: 'code',
  scope: 'email+openid',
  apikey: "j4z2Zolw9H8nQkuZzZVlDGDBtyX5u0jPSAM-LEEdRdo",
  encryptionKey: 'secret key 123',
  supported_language: ['en-GB', 'en', 'de', 'de-DE', 'fr-FR', 'es-ES', 'cs-CZ', 'ko-KR', 'it-IT'],
  featureFlagLink:'../../assets/json/feature-flags-int.json',
};
