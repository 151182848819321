import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AwsAuthService } from '../services/aws-auth.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  private errorCodes: number[] = [401, 403];
  constructor(private authAmplifyService: AwsAuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // let isExcludedApi = false;
        // for(let i=0; i<this.excludedApis.length; i++) {
        //   if(request.url.includes(this.excludedApis[i])) {
        //     isExcludedApi = true;
        //     break;
        //   }
        // }
        if (this.errorCodes.includes(+error.status)) {
          this.handleAuthorizationErrors();
          return throwError(error);
        } else {
          const errorMsg = this.displayServerErrorMessage(error);
          console.log('error',errorMsg)
          return throwError(error);
        }
      })
    );
  }

  private handleAuthorizationErrors() {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('currentCIAMUser');
    this.authAmplifyService.manageLoginResponse();
  }

   //  Avoid to pass the real errors to client
   private displayServerErrorMessage(error: HttpErrorResponse): any {
    switch (error.status) {
      case 403: {
        return `Unauthorized access`;
      }
      case 500: {
        return `Resource not found`;
      }
      case 400: {
        return `Cluster not available for the tour `;
      }
    }
  }

}
