import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ProfileDetails } from 'src/app/components/interfaces/myStories.interface';
import { EventInfo } from 'src/app/interfaces/event-info.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyStoriesService {

  private _participantInfo: EventInfo = <any>null;
  private userProfileDetails: ProfileDetails = <any>null;
  private _ciamId: string = '';

  constructor(private _httpClient: HttpClient) { }

  public set particpantInfoResponse(data : EventInfo) {
    this._participantInfo = data;
  }

  public get particpantInfoResponse(): EventInfo {
    return this._participantInfo;
  }

  private editProfileClickedSubject = new BehaviorSubject<boolean>(false);
  private editProfileClosedSubject = new BehaviorSubject<boolean>(false);

  private _isProfileUpdated$ = new BehaviorSubject<boolean>(false);

  editProfileClicked$ = this.editProfileClickedSubject.asObservable();
  editProfileClosed$ = this.editProfileClosedSubject.asObservable();


  private showSpinnerSubject = new BehaviorSubject<boolean>(false);
  showSpinnerSubject$ = this.showSpinnerSubject.asObservable();

  public showSpinner(): void {
    this.showSpinnerSubject.next(true);
  }

  public hideSpinner(): void {
    this.showSpinnerSubject.next(false);
  }

  public editProfilePopupVisible(visible: boolean): void {
    this.editProfileClickedSubject.next(visible);
  }

  public setIsProfileUpdate(isUpdated: boolean): void {
    this._isProfileUpdated$.next(isUpdated)
  }

  public getIsProfileUpdated(): Observable<boolean> {
    return this._isProfileUpdated$.asObservable()
  }

  public editProfilePopupClosed(): void {
    this.editProfileClosedSubject.next(true);
  }

  public set profileInfoResponse(data: ProfileDetails) {
    this.userProfileDetails = data;
  }

  public get profileInfoResponse(): ProfileDetails {
    return this.userProfileDetails;
  }

  public set ciamId(userId: string) {
    this._ciamId = userId;
  }

  public get ciamId(): string{
    return this._ciamId;
  }

  /**
   * @description Fetches garage details for a given user
   * @param ciamId
   * @returns Observable
   */
  public getGarageDetails(ciamId?: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('ciamId', ciamId)
      .set('Content-Type', 'application/json');
    let url = ''
    if (localStorage.getItem('isLoggedIn') === 'true') {
      url = environment.proxyEndpoint + '/stories/getGarageData';
    } else {
      url = environment.proxyEndpoint + '/stories/getPublicGarageData';
    }
    const body = {
      "latestTimestamp": 0
    }
    return this._httpClient.post(url, body, { headers: headers, withCredentials: true });
  }

  /**
   * @description Fetches stories for a given VIN or FIN
   * @param vinOrFin 
   * @param ciamId 
   * @param pageNumber 
   * @param pageSize 
   * @returns Obserbable
   */
  public getStroiesForVin(vinOrFin: any, ciamId: any, pageNumber: number, pageSize: number): Observable<any>{
    let api_header = {
      headers: { 'Content-Type': 'application/json', 'vinOrFin': vinOrFin, 'ciamId': ciamId },
    };
    let url = '';
    if (localStorage.getItem('isLoggedIn') === 'true') {
      url = `${environment.proxyEndpoint}/stories/getStories?pageSize=${pageSize}&pageNumber=${pageNumber}`
    } else {
      url = `${environment.proxyEndpoint}/stories/getPublicStoriesForCar?pageSize=${pageSize}&pageNumber=${pageNumber}`
    }
    return this._httpClient.get<any>(url, {headers: api_header.headers, withCredentials: true});
  }

  /**
   * @description Fetches user details and displays in header, garage and edit profile
   * @param userId 
   * @returns Observable
   */
  public getProfile(userId?: any): Observable<any> {
    let api_header = {headers: {}};
    if (userId) {
      api_header = {
        headers: {'userId': userId}
      };
    }
    let url = '';
    if (localStorage.getItem('isLoggedIn') === 'true') {
      url = `${environment.proxyEndpoint}/stories/getUser?isStories=1`;
    } else {
      url = `${environment.proxyEndpoint}/stories/getPublicProfileData`;
    }
    return this._httpClient.get<any>(url, {headers: api_header.headers, withCredentials: true});
  }

  /**
   * @description This API is called in /register/:eventId route
   * Fetches user details and displays in slef-regsitration flow
   * @param eventId 
   * @returns Observable
   */
  public getUserInfoForSelfRegistration(eventId: string): Observable<any>{
    const baseUrl = environment.proxyEndpoint
    const url= `${baseUrl}/stories/events/fetchRegistrationDetails/${eventId}`;       
    // const url= `${baseUrl}api/v2/customer_ciam/events/${eventId}/register`;
    return this._httpClient.get(url, {withCredentials: true});
  }
  
  /**
   * @description This API is called on click of register button in Self-registration flow
   * @param: Event ID
   */
  public registerUser(eventId: string): Observable<any>{
    const baseUrl = environment.proxyEndpoint
    const url= `${baseUrl}/stories/events/registerUser/${eventId}`;
    return this._httpClient.post(url,{}, {withCredentials: true});
  }

  /**
   * @description This API is called on click of Update button in edit profile popup.
   * Can update nickname, description and social link
   * @param nickName 
   * @param description 
   * @param socialLink 
   * @param userId 
   * @returns Observable
   */
  public updateProfile(nickName: string, description: string, socialLink: string, userId: any): Observable<any> {
    const api_header = { 'Content-Type': 'application/json', 'userId': userId};
    const baseUrl= environment.proxyEndpoint +'/stories/updateUser?isStories=1';
    const url=`${baseUrl}`;
    return this._httpClient.put(url,{nickName, description, socialLink},{headers: api_header, withCredentials: true});
  }

  /**
   * @description called post successful CIAM login; will have customer details and
   * event details. Called when we reach the link-participant page
   * @param participantId
   * @param participantKey
   * @returns Participant details and event details
   */
  public getParticipantInfo(participantId: any, participantKey: any): Observable<any>{
    const baseUrl = environment.proxyEndpoint
    const url = `${baseUrl}/stories/participants/getParticipantById/${participantKey}/${participantId}`
    // const url= `${baseUrl}api/v2/customer_ciam/participant/${participantId}?participantSecretKey=${participantKey}`;
    return this._httpClient.get(url, {withCredentials: true});
  }

  /**
   * @description called on confirm click and refresh
   * @param participantId
   * @param participantKey
   * @returns event data
   */
  public updateCiamConfirmation(participantId: any, participantKey:any): Observable<any> {
    // const baseUrl= environment.serverUrl;
    const baseUrl = environment.proxyEndpoint;
    const url = `${baseUrl}/stories/participants/confirmParticipantLink/${participantKey}/${participantId}`
    return this._httpClient.put(url,{}, {withCredentials: true});
  }

  public isValidGuID(guID: any){
    const regex = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/
    return regex.test(guID)
  }
  public getEventDetails(eventId: any): Observable<any>{
    let res = {
      "eventId": "792b94b1-66f8-44ca-8c07-66a7fb61a490",
      "eventName": "Cars & Coffee Stuttgart",
      "eventLogo": "https://images.unsplash.com/photo-1541348263662-e068662d82af?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3BvcnRzJTIwY2FyfGVufDB8fDB8fHww",
      "eventBannerUrl": "https://images.unsplash.com/photo-1541348263662-e068662d82af?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3BvcnRzJTIwY2FyfGVufDB8fDB8fHww",
      "startDate": "2025-01-01T16:32:08.858Z",
      "endDate": "2025-12-31T16:32:08.858Z",
  }
    return of(res);
  }


}
